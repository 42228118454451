import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const LazyLoadTrackers = (props) => {
  const { renderChild,
          bottomReached,
          imeiFilter
          } = props;
  const [trackers, setTrackers] = useState([]);
  const [next, setNext] = useState("/api/trackers/?page=1");
  const [renderItems, setRenderItems] = useState([]);


  const navigate = useNavigate();

  // Load page of trackers (100 per page) if the page is valid
  function loadNextPage() {
    if (next) {
      axios.get(next).then((res) => {
        next == 1
          ? setTrackers(res.data.results)
          : setTrackers([...trackers, ...res.data.results]);
        setNext(res.data.next);
      });
    }
  }
  
  function updateSelection(imei){
    axios
    .get("/api/trackers/search_by_imei/?imei=" + imei)
    .then((res) => {
      setNext("/api/trackers/?page=1");
      var t = res.data.results;
      t.forEach((ts,index)=>{
        ts.volume = res.data.quota[index].volume;
        ts.total_volume = res.data.quota[index].total_volume;
      });
      setTrackers(t);
    });
  }

  function handleLineClick(imei) {
      navigate("/trackers/imei/" + imei, { replace: true });
  }

  // Reset the page to the first and load it
  function loadFirstPage() {
    setTrackers([]);
    loadNextPage();
  }
  // Initialisation
  useEffect(() => {
    loadNextPage();
  }, []);

  // Load next elements if bottom reached
  useEffect(() => {
    if (bottomReached) {
      loadNextPage();
    }
  }, [bottomReached]);

  // Render elements when trackers change
  useEffect(() => {
    var toRender = [];
    trackers.forEach((tracker,index) => {
      toRender.push(renderChild(tracker,updateSelection,handleLineClick));
    });
    setRenderItems(toRender);
  }, [trackers, setRenderItems]);

  // Load only match
  useEffect(() => {
    if (imeiFilter.length > 2) {
      axios
        .get("/api/trackers/search_by_imei/?imei=" + imeiFilter)
        .then((res) => {
          setNext("/api/trackers/?page=1");
          setTrackers(res.data.results);
        });
    } else if (imeiFilter.length == 0) {
      loadFirstPage();
    }
  }, [imeiFilter]);

  return <>{renderItems}</>;
};

export { LazyLoadTrackers };
