import { useState, useRef, useEffect } from "react";
import { Header } from "../../common";
import { Input, Table } from "reactstrap";
import { LazyLoadTrackers } from "./LazyLoadTrackers";

const TableTrackers = (props) => {
  const { headers, renderTracker } = props;
  const [bottomReached, setBottomReached] = useState(false);
  const imeiInput = useRef("");
  const [imeiFilter, setImeiFilter] = useState("");

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, true );
  }, []);


  // Handle the scrolling to detect when the bottom is reached
  function handleScroll(e) {
      setBottomReached(
        e.target.scrollingElement.scrollHeight - e.target.scrollingElement.scrollTop === e.target.scrollingElement.clientHeight
      );
    }

  // Handle IMEI filtering
  function handleImeiChange(e) {
    setImeiFilter(imeiInput.current.value);
  }

 
  return (
      <div className="Trackers">
        <Header/>
        <div className="hoot-content">

        <main className="container">
          <Table style={{ "overflow-y": "scroll" }}>
          <thead>
            <tr>
              {headers.map((header, index) => {
                return header.isFilteringInput ? (
                  <th key={index}>
                    <Input
                      innerRef={imeiInput}
                      onKeyUp={handleImeiChange}
                      placehoder={header.label}
                    ></Input>
                  </th>
                ) : (
                  <th key={index}>{header.label}</th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            <LazyLoadTrackers
              bottomReached={bottomReached}
              renderChild={(tracker,updateSelection,handleLineClick) => renderTracker(tracker,updateSelection,handleLineClick,undefined)}
              imeiFilter={imeiFilter}
            ></LazyLoadTrackers>
          </tbody>
          </Table>
        </main>
        </div>
      </div>
    
  );

};

export { TableTrackers };
